<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.time_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<script>
import $ from "jquery";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import PersonHeader from "@/views/pages/examinee/person-header.vue";
import { regionData } from "element-china-area-data"; //省市区不带'全部'的三级联动
import {
  handleChange,
  changeOption,
  getDictionary,
  boundDefaultData,
  getUserInfo
} from "@/libs/common";
import {
  getUserFamily,
  updateUserFamily,
  deleteUserFamily,
  getUserWork,
  updateUserWork,
  deleteUserWork,
} from "@/api/userInfo.js";

/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
  },
  data() {
    return {
      title: "个人资料完善",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "工作经历",
          active: true,
        },
      ],
      userInfo:{},
      page: "work",
      sjzd: {},
      showmodal: false,
      showmodal2: false,
      operateType: "add",
      value1: "",
      options: regionData, //省市区数据
      pickerOptions_start: "",
      pickerOptions_end: "",

      familyList: [],
      workList: [],

      workForm: {
        gzqsrq: "",
        gzzzrq: "",
        gzdw: "",
        zw: "",
      },
      familyForm: {
        gxmc: "",
        gxm: "",
        cyxm: "",
        gzxxdw: "",
        zw: "",
      },
      rules: {
        gzqsrq: [
          {
            required: true,
            message: "请选择工作起始时间",
            trigger: "blur",
          },
        ],
        gzzzrq: [
          {
            required: true,
            message: "请选择终止时间",
            trigger: "blur",
          },
        ],
        gzdw: [
          {
            required: true,
            message: "请输入工作单位名称",
            trigger: "change",
          },
        ],
      },
      familyRules: {
        gxm: [
          {
            required: true,
            message: "请选择成员关系",
            trigger: "change",
          },
        ],
        cyxm: [
          {
            required: true,
            message: "请输入成员姓名",
            trigger: "change",
          },
        ],
      },
      show:''
    };
  },
  methods: {
    open(sid, type) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == "family") {
            deleteUserFamily(sid).then((res) => {
              if (res.status) {
                this.getFamily();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          } else if (type == "work") {
            deleteUserWork(sid).then((res) => {
              if (res.status) {
                this.getWork();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    checkForm() {
      this.$refs["workForm"].validate((valid) => {
        if (valid) {
          updateUserWork(this.workForm).then((res) => {
            if (res.status) {
              this.showmodal = false;
              this.getWork();
            }
          });
          return false;
        }
        // this.$router.push('/forgot-password2')
      });
    },
    checkForm2() {
      this.$refs["familyForm"].validate((valid) => {
        if (valid) {
          updateUserFamily(this.familyForm).then((res) => {
            if (res.status) {
              this.showmodal2 = false;
              this.getFamily();
            }
          });
          return false;
        }
      });
    },

    addMess(type) {
      this.operateType = "add";
      if (type == "family") {
        this.showmodal2 = true;
        for (const key in this.familyForm) {
          this.familyForm[key] = "";
        }
      } else {
        this.showmodal = true;
        for (const key in this.workForm) {
          this.workForm[key] = "";
        }
      }
    },
    editFamily(e) {
      this.operateType = "edit";
      let index = $(e.target).parent().attr("index");
      this.familyForm = JSON.parse(JSON.stringify(this.familyList[index]));

      let formData = this.familyForm;
      let data = {
        dict_xb: "xbm",
        dict_gx: "gxm",
        dict_mz: "mzm",
        dict_jkzk: "jkzkm",
        dict_cye: "cyem",
        dict_gjdq: "gjdqm",
      };
      boundDefaultData(this.sjzd, formData, data);
      this.$forceUpdate();
      this.showmodal2 = true;
    },
    deleteFamily: function (e) {
      let index = $(e.target).parent().attr("index");
      let sid = this.familyList[index].sid;
      this.open(sid, "family");
    },
    editWork(e) {
      this.operateType = "edit";
      let index = $(e.target).parent().attr("index");
      this.workForm = JSON.parse(JSON.stringify(this.workList[index]));

      let formData = this.workForm;
      let data = {
        dict_gjdq: "gjdqm",
      };
      boundDefaultData(this.sjzd, formData, data);
      this.$forceUpdate();
      this.showmodal = true;
    },
    deleteWork: function (e) {
      let index = $(e.target).parent().attr("index");
      let sid = this.workList[index].sid;
      this.open(sid, "work");
    },

    handleChange(event, form, name, code) {
      handleChange(event, form, name, code);
    },
    changeOption(event, form, kv) {
      changeOption(event, form, kv);
      this.$forceUpdate();
    },

    changeDate() {
      let _this = this;
      //因为date1和date2格式为 年-月-日， 所以这里先把date1和date2转换为时间戳再进行比较
      let date_start = new Date(_this.workForm.gzqsrq.replace(/-/g, "/")).getTime();
      let date_end = new Date(_this.workForm.gzzzrq.replace(/-/g, "/")).getTime();
      console.log(date_start);
      console.log(date_end);

      _this.pickerOptions_start = {
        disabledDate: (time) => {
          if (date_end != "") {
            return time.getTime() > Date.now() || time.getTime() > date_end;
          } else {
            return time.getTime() > Date.now();
          }
        },
      };
      _this.pickerOptions_end = {
        disabledDate: (time) => {
          return time.getTime() < date_start || time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "至今",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      };
    },
    model() {
      let sjzd = this.sjzd;
      let fromData = this.EduactionForm;
      let data = {
        dict_gjdq: "gjdqm",
        dict_xl: "xlm",
      };
      boundDefaultData(sjzd, fromData, data);
      console.log(fromData);
      this.$forceUpdate();
    },
    getFamily() {
      getUserFamily().then((res) => {
        if (res.status) {
          this.familyList = res.data;
        }
      });
    },
    getWork() {
      getUserWork().then((res) => {
        if (res.status) {
          this.workList = res.data;
        }
      });
    },
    init() {
      this.getFamily();
      this.getWork();
      getDictionary(
        "dict_gjdq",
        "dict_gx",
        "dict_cye",
        "dict_jkzk",
        "dict_mz",
        "dict_xb"
      ).then((res) => {
        res.dict_gx = res.dict_gx.filter(v=>{
        return v.value[0] != 0 && v.value[1] != 0
      })
        this.sjzd = res;
      });
      this.changeDate();
    },
  },
  created() {
    let show = this.$route.query.show;
    if (show) {
      this.show = show;
    }
    this.init();
    let _this = this
    getUserInfo().then((data) => {
      if (data) {
        _this.userInfo = data;
      }
    })
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" :show="show"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-4">工作经历及家庭成员信息</span>
              <span class="mr-2"
                ><i class="iconfont icon-ze-user-o mr-2"></i>姓名</span
              >
              <span class="mr-2">{{ userInfo.xm }}</span>
              <span class="mr-2"
                ><i class="iconfont icon-idcard1e mr-2"></i>身份证号</span
              >
              <span class="mr-2">{{ userInfo.sfzjh }}</span>
            </h4>
            <div>
              <div class="mb-4">
                <img
                  src="@/assets/images/person/if-plus-circle.png"
                  class="mr-2"
                /><button
                  type="button"
                  class="btn btn-info h30 mr-2"
                  v-b-modal.addFamily
                  @click="addMess('family')"
                >
                  添加家庭成员
                </button>
              </div>
              <div class="table-responsive border">
                <table
                  class="table light-table table-hover text-center"
                  v-if="familyList.length != 0"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 5%">序号</th>
                      <th style="width: 10%">关系</th>
                      <th style="width: 10%">姓名</th>
                      <th style="width: 10%">工作学习单位</th>
                      <th >职务</th>
                      <th style="width: 8%">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in familyList" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ item.gxmc }}</td>
                      <td>{{ item.cyxm }}</td>
                      <td>{{ item.gzxxdw || "/" }}</td>
                      <td>{{ item.zw || "/" }}</td>
                      <td class="handle" :index="index">
                        <i
                          class="iconfont icon-icon_edit mr-2"
                          @click="editFamily"
                        ></i
                        ><i
                          class="iconfont icon-riLine-delete-bin-line mr-2"
                          @click="deleteFamily"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <div class="mb-4">
                <img
                  src="@/assets/images/person/if-plus-circle.png"
                  class="mr-2"
                /><button
                  type="button"
                  class="btn btn-info h30 mr-2"
                  v-b-modal.addWork
                  @click="addMess('work')"
                >
                  添加工作经历
                </button>
              </div>
              <div class="table-responsive border">
                <table
                  class="table light-table table-hover text-center"
                  v-if="workList.length != 0"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 5%">序号</th>
                      <th style="width: 15%">起止时间</th>
                      <th style="width: 10%">单位名称</th>
                      <th>职务</th>
                      <th style="width: 8%">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in workList" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ item.gzqsrq }}至{{ item.gzzzrq }}</td>
                      <td>{{ item.gzdw || "/" }}</td>
                      <td>{{ item.zw || "/" }}</td>
                      <td class="handle" :index="index">
                        <i
                          class="iconfont icon-icon_edit mr-2"
                          @click="editWork"
                        ></i
                        ><i
                          class="iconfont icon-riLine-delete-bin-line mr-2"
                          @click="deleteWork"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <a href="/recordList"
              ><button type="button" class="btn btn-info h35 sub-btn">
                已完善，继续下一步
              </button></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 添加工作经历弹窗 -->
    <b-modal
      id="addWork"
      v-model="showmodal"
      centered
      :title="operateType == 'add' ? '添加工作经历' : '修改工作经历'"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <el-form
        :model="workForm"
        :rules="rules"
        ref="workForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="time_group">
          <el-form-item label="工作起始日期" prop="gzqsrq" class="w-50">
            <el-date-picker
              v-model="workForm.gzqsrq"
              type="date"
              class="w-100"
              placeholder="工作起始日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_start"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工作终止日期" prop="gzzzrq" class="w-50">
            <el-date-picker
              v-model="workForm.gzzzrq"
              type="date"
              class="w-100"
              placeholder="工作终止日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_end"
              @input="changeDate"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="单位名称" prop="gzdw">
          <el-input
            type="text"
            v-model="workForm.gzdw"
            placeholder="请输入单位名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="zw">
          <el-input
            type="text"
            v-model="workForm.zw"
            placeholder="请输入职务"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-info h30 w-md mr-3"
              @click="checkForm"
            >
              {{ operateType == "add" ? "确定添加" : "确定修改" }}
            </button>
            <button
              type="button"
              class="btn btn-secondary h30 w-md"
              @click="showmodal = false"
            >
              取消
            </button>
          </div>
        </el-form-item>
      </el-form>
    </b-modal>
    <!-- 添加工作经历弹窗完-->
    <!-- 添加家庭成员信息 -->
    <b-modal
      id="addFamily"
      v-model="showmodal2"
      centered
      size="lg"
      :title="operateType == 'add' ? '添加家庭成员信息' : '修改家庭成员信息'"
      title-class="font-18"
      hide-footer
    >
      <el-form
        :model="familyForm"
        :rules="familyRules"
        ref="familyForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="cyxm">
          <el-input
            type="text"
            v-model="familyForm.cyxm"
            placeholder="请输入家庭成员姓名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="关系" prop="gxm">
          <el-select
            v-model="familyForm.gxm_"
            class="w-100"
            placeholder="请选择成员关系"
            value-key="name"
            @change="changeOption($event, familyForm, ['gxmc', 'gxm'])"
          >
            <el-option
              v-for="(v, i) in sjzd.dict_gx"
              :label="v.name"
              :value="v"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作学习单位" prop="gzxxdw">
          <el-input
            type="text"
            v-model="familyForm.gzxxdw"
            placeholder="请输入成员工作学习单位"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="zw">
          <el-input
            type="text"
            v-model="familyForm.zw"
            placeholder="请输入成员职务"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-info h30 w-md mr-3"
            @click="checkForm2"
          >
            {{ operateType == "add" ? "确定添加" : "确定修改" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary h30 w-md"
            @click="showmodal2 = false"
          >
            取消
          </button>
        </div>
      </el-form>
    </b-modal>
    <!-- 添加家庭成员信息完 -->
  </Layout>
</template>
